

@keyframes moveInRight {
    0%{
        opacity: 0;
        transform: translateX(200px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes moveInLeft{
    0%{
        opacity: 0;
        transform: translateX(-200px);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

