.endereco {
  grid-column: full-start / full-end;
}

.contato {
  grid-column: center-start / center-end;

  @include respond(phone){
    grid-column: full-start /full-end;
  }

  &__title{
    color: white;
    background-color: #3d3d3d;
    text-align: left;
    padding: 2rem 0 2rem 5rem;
  }
  &__box {
    padding: 10rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone){
      flex-direction: column;
    }
    &-input {
      display: flex;
      flex-direction: column;

      label {
        margin: 1.5rem 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: #3d3d3d;
      }

      input,
      textarea {
        padding: 1rem;
        border-radius: 0.3rem;
        border-style: none;
        background-color: rgba($color: black, $alpha: 0.05);

        &::placeholder {
          font-weight: 600;
          font-family: "Montserrat";
          opacity: 0.6;
        }
      }

      input {
        height: 4.5rem;
      }

      textarea {
        height: 15rem;
      }
    }
  }

  &__endereco {

    background-color: rgba($color: #000000, $alpha: .03);
    padding: 7rem;
    height: min-content;
    p {
      font-size: 2rem;
      font-weight: bold;
      color: #3d3d3d;
      margin-bottom: 2rem;
    }
  }

  &__form {
    width: 50%;

    @include respond(phone){
      width: 90%;
      margin-top: 4rem;
    }
  }

  &__button {
    margin-top: 2rem;
    width: 13rem;
    height: 3.5rem;
    color: white;
    background-color: #3d3d3d;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.2s;

    &:hover {
      background-color: lightgray;
      color: #3d3d3d;
    }
  }
}
