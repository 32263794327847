%heading {
  font-family: $font-primary;
  font-weight: 400;
}

.heading-3{
  font-size: 3rem;
  text-transform: uppercase;
}

.heading-6{
  font-size: 1.5rem;
}


.paragraph{
  font-size: 1.8rem;
}