// COLOR VARIABLES
$color-primary: #2100ee;
$color-secondary: #ff650e;
$color-tertiary: #f0f2ff;
$color-quaternary: #f92103;

$color-text-primary: #1c1c1c;
$color-text-secondary: #21252e;
$color-text-tertiary: #363636;
$color-text-quaternary: #7c7c7c;

$background-color-primary: #fff1e9;
$background-color-secondary: #f9fcff;

// FONT VARIABLES
$font-primary: "Montserrat", sans-serif;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
  scroll-behavior: smooth;

  
  @include respond(tab-land){// width < 900?
    font-size: 56.25%;//1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port){//width < 600?
    font-size: 50%;//1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop){
    font-size: 75%;//1rem = 12, 12/16
  }
}

body {
  font-family: $font-primary;
  font-weight: 400;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: initial;
}

.container {
  display: grid;
  grid-template-rows: repeat(8, min-content);
  grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start] repeat(
      6,
      [col-start] minmax(min-content, 25rem) [col-end]
    ) [center-end] minmax(6rem, 1fr) [full-end];
}