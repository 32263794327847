.footer{
    grid-column: full-start / full-end;
    background-color: #1C1C1C;
    color: white;
    padding: 3rem 0;

    &__paragraph{
        font-size: 1.6rem;
        text-align: center;
    }
}