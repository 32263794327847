.header{

    grid-column: full-start / full-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    &__logo{
        width: 5rem;
        margin: 1rem 0;
    }
    &__user-nav{

        width: 100%;
        background-color: #1C1C1C;

        @include respond(phone){
            display: none;
        }
    }
    &__list{
        margin: 0 auto;
        width: 50%;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: stretch;

        &-item{
            color: white;
            cursor: pointer;
            font-weight: 500;
            transition: .3s;
            width: 17rem;
            text-align: center;
            padding: 1rem 1.5rem;
            &:hover{
                background-color: lightgray;
                color: #1C1C1C;
            }
        }
    }
}