.filosofia {
  padding: 10rem 5rem;
  text-align: center;
  position: relative;
  background-color: rgba($color: #000000, $alpha: .01);

  &::before{
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("https://png.pngtree.com/png-clipart/20230330/original/pngtree-scales-of-justice-png-image_9010916.png");
      background-repeat: no-repeat;
      background-size: 30%;  
      background-position: 100%;
      opacity: .05;
  }
  
  &__title {
    color: #3d3d3d;
  }

  &__paragraph {
    color: #3d3d3d;
    margin-top: 4rem;

    @include respond(tab-land) {
      width: 90%;
    }
  }

  &__box-text {
    width: 73%;
    margin: 0 auto;
  }
}
