.parallax-background{
    grid-column: full-start / full-end;
    height: 30rem;
    @include respond(tab-land){
        height: 20rem;
    }

    background-image: url('../img/advocacia.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;

    &--office{
        background-image: url('../img/advocacia2.jpg');
    }
}