.quem-somos{
    grid-column: center-start / center-end;
    display: flex;
    align-items: center;

    @include respond(phone){
        flex-direction: column;
    }
    padding: 10rem 5rem;

    &__title{
        color: #3d3d3d;
    }

    &__paragraph{
        color: #3d3d3d;
        margin-top: 4rem;
        width: 85%;

        @include respond(tab-land){
            width: 90%;
        }
    }

    &__img{
        width: 50rem;
        height: 40rem;

        @include respond(tab-land){
            width: 40rem;
            height: 30rem;
        }

        
        @include respond(phone){
            margin-top: 5rem;

            width: 80%;
        }

        object-fit: cover;
        box-shadow: 0 1.5rem 4rem rgba(0,0,0, .4);
        border-radius: 4px;
    }
}


.activeInRight{
    animation-name: moveInRight;
    animation-duration: 1s;

    opacity: 1;
}

.activeInLeft{
    animation-name: moveInLeft;
    animation-duration: 1s;

    opacity: 1;
}
