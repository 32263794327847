.slider {
    grid-column: full-start / full-end;
    display: block;
    height: 81vh;

    @include respond(tab-land) {
        height: 65vh;
    }

    @include respond(phone) {
        height: 80vh;
    }

    position: relative;
    background-color: #1c1c1c;
    &__img {
        width: 100%;
        height: 81vh;
        opacity: 0.28;
        object-fit: cover;
    }

    &__box-text {
        position: absolute;
        color: white;
        font-family: "Tinos", sans-serif;
        font-weight: 400;
        text-align: center;
        z-index: 1;
        left: 10%;
        top: 25%;

        @include respond(tab-land) {
            left: 5%;
        }

        @include respond(phone) {
            left: 0;
            right: 0;
        }

        animation-name: moveInLeft;
        animation-duration: 2s;
    }

    &__title {
        font-size: 12rem;

        @include respond(tab-land) {
            font-size: 10rem;
        }
    }

    &__subtitle {
        font-size: 3rem;
        letter-spacing: 12px;

        @include respond(tab-land) {
            font-size: 2rem;
            letter-spacing: 12px;
        }

        @include respond(phone) {
            letter-spacing: 10px;
        }
    }
}
