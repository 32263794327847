.equipe {
  grid-column: center-start / center-end;
  margin-top: 10rem;
  &__title {
    color: #3d3d3d;
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    list-style: none;
    padding: 10rem 5rem;

    width: 100%;

    &-box{

      position: relative;
      width: 28%;

      @include respond(tab-land){
        width: 32%;
      }

      p{
        font-size: 2.3rem;
        font-weight: 600;
        color: #3d3d3d;
        width: 100%;
        text-align: center;
        background-color: rgba(255,255,255, .9);
        padding: 2rem 0;
        bottom: 0;
        position: absolute;
        transition: .4s;
        opacity: 0;
        transform: translateY(7rem);
      }

      &:hover{
        p{
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 35rem;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    margin-bottom: -.35rem;
    &:hover {
    }
  }
}
