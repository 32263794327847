//MEDIA QUERY MANAGER
/*

    DESKTOP FIRST

    0 - 600px -> Phone
    600 - 900px -> Tablet Portrait
    900 - 1200px -> Tablet Landscape
    [1200 - 1800px] -> is where our normal styles apply
    1800px + -> Big desktop

    $breakpoint argument choices:
    - phone
    - tab-port
    - tab-land
    - big-desktop

    ORDER: Base + typograpgy > general layout + grid > page layout > components
    1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {//600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {//900px
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {//1200px
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {//1800px
      @content;
    }
  }
}
