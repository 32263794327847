.areas-atuacao {
  grid-column: full-start / full-end;

  @include respond(phone) {
    grid-column: full-start / full-end;
  }

  padding: 10rem 5rem;

  &__title {
    text-align: center;
    margin-bottom: 6rem;
    color: #3d3d3d;
  }

  &__list {
    list-style: none;
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      padding-left: 3rem;
    }
  }

  &__item {
    background-position: 50%;
    background-size: cover;
    width: 30rem;
    height: 40rem;
    border-radius: 5px;
    transition: 0.3s;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 5px;
      background: linear-gradient(to bottom, transparent 35%, rgba(0,0,0,0.8) 100%);
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      transform: scale(1.03);
    }

    p {
      width: 22rem;
      position: absolute;
      text-transform: uppercase;
      bottom: 10%;
      left: 5%;
      font-size: 25px;
      color: white;
      font-weight: 600;
      z-index: 999;

      &::after {
        content: "";
        width: 6rem;
        height: 0.2rem;
        position: absolute;
        bottom: -10%;
        left: 0;
        background-color: white;
        border-radius: 20px;
      }
    }
  }
}

.active {
  background-color: #1c1c1c;
  color: white;
}

.paragraph-active {
  opacity: 1;
}

.activeInRight {
  animation-name: moveInRight;
  animation-duration: 1s;

  opacity: 1;
}

.activeInLeft {
  animation-name: moveInLeft;
  animation-duration: 1s;

  opacity: 1;
}

.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0; 
  visibility: hidden; 
  transition: opacity 0.3s ease, visibility 0.3s ease; 


  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 70%;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: #1c1c1c;
    font-size: 23px;

    padding: 5rem;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      color: white;
      background-color: #1c1c1c;
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 3px;
      cursor: pointer;
      transition: .2s;

      &:hover {
        color: #1c1c1c;
        background-color: white;
      }
    }
  }
}
