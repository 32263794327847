.servicos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 5rem;

  &__title {
    color: #3d3d3d;
  }

  &__img-box {
    position: relative;
    width: 50rem;
    height: 30rem;
  }

  &__img {
    object-fit: cover;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    position: absolute;
    width: 30rem;
    height: 15rem;

    &:nth-of-type(1) {
        top: 0;
        left: 3%;
    }

    &:nth-of-type(2) {
        top: 50px;
        left: 10%;
    }

    &:nth-of-type(3) {
        top: 91px;
        left: 19%;
    }

    &:nth-of-type(4) {
        top: 148px;
        left: 32%;
    }
  }

  &__box-text {
    width: 60%;
  }

  &__paragraph {
    color: #3d3d3d;
    margin-top: 4rem;

    @include respond(tab-land) {
      width: 90%;
    }
  }
}
